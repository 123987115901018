import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Icon } from "@iconify/react";
import { styled } from "@mui/material/styles";
import { IconButton, CircularProgress } from "@mui/material";
import closeFill from "@iconify/icons-eva/close-fill";
import { get_root_value } from "src/utils/domUtils";

import { useState } from "react";
import { useEffect } from "react";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 500,
  maxWidth: "90%",
  flexDirection: "column",
  padding: "20px",
  // height: 400,
  bgcolor: get_root_value("--sidebars-background-color"),
  border: "1px solid #fff",
  boxShadow: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "15px",
};
const ProgrammesImgStyle = styled("img")({
  height: "400px",
  objectFit: "contain",
  width: "100%",
});

export default function DocumentModelBox({
  open,
  handleClose,
  image_url,
  audio_url,
  type,
  previewDoc,
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [videoVisible, setVideoVisible] = useState(false);
  const [audioVisible, setAudioVisible] = useState(false);

  useEffect(() => {
    setIsLoaded(false);
  }, [image_url]);

  useEffect(() => {
    // Show/hide video based on modal open/close state
    setVideoVisible(open && type === "embed_code" && !!previewDoc?.embed_code);
    setAudioVisible(open && type === "audio");
  }, [open, type, previewDoc]);

  // const randomParam = Date.now();
  const randomParam = Math.random();
  return (
    <div>
      <Modal
        keepMounted
        open={open}
        onClose={handleClose}
        aria-labelledby="keep-mounted-modal-title"
        aria-describedby="keep-mounted-modal-description">
        <Box sx={style} className="mui-btn-close">
          <IconButton onClick={handleClose} className="mui-btn-close-icon">
            <Icon icon={closeFill} width={20} height={20} />
          </IconButton>
          {type == "image" && (
            <ProgrammesImgStyle
              src={image_url}
              onContextMenu={(e) => e.preventDefault()}
            />
          )}

          {type == "other_document" && (
            <>
              {!isLoaded && (
                <div className="loading-preview">
                  <CircularProgress className="loading-preview-svg" />
                </div>
              )}
              {image_url && (
                <iframe
                  className="other-resource"
                  width="100%"
                  frameborder="0"
                  preload="auto"
                  onLoad={() => setIsLoaded(true)}
                  onError={() => console.log("Error loading document")}
                  // onError={console.log("Error loading document")}
                  src={`https://docs.google.com/gview?url=${image_url}&embedded=true`}></iframe>
              )}
            </>
          )}
          {type == "audio" && (
            <div className="audio-resource">
              <h3 className="audio-title">{previewDoc?.title}</h3>
              {audioVisible && (
                <div
                  className="col-12 section-space"
                  style={{ textAlign: "center" }}>
                  <audio
                    className="w-100"
                    src={image_url}
                    controls
                    controlsList="nodownload"
                    onContextMenu={(e) => e.preventDefault()}
                  />
                </div>
              )}
            </div>
          )}
          {type == "embed_code" && (
            <div className="embed-resource">
              {videoVisible && (
                <div
                  style={{ textAlign: "center" }}
                  dangerouslySetInnerHTML={{
                    __html: previewDoc?.embed_code,
                  }}></div>

                // <iframe src="https://ailsdoherty00.outgrow.us/661fa01a7100b01ca9f0a390"></iframe>
              )}
            </div>
          )}
        </Box>
      </Modal>
    </div>
  );
}
